.form_default {
    #{$text-inputs} {
        background: transparent;
        border-radius: 7px;
        border: 1px solid rgba($color-purple, 0.2);
        padding: 12px 13px;
        height: 40px;
        width: 100%;
        @extend %form-label;
        background-color: $color-light;
        box-shadow: none;
        appearance: none;
        @include anim-f(border);

        &:focus,
        &:hover {
            border-color: $color-purple !important;
        }

        &.validation-touched:invalid {
            border-color: $color-red;
        }

        // &.validation-touched:valid{
        //     border-color: $color-green;
        // }

        &.clear {
            border-color: transparent !important;
            background-color: transparent;

            &::placeholder {
                color: inherit;
            }
        }
    }

    input[type="number"] {
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            visibility: hidden;
            appearance: none;
        }
    }

    textarea {
        resize: none;
        height: 150px;
    }

    &--card_field {
        padding-right: 60px !important;
        background-position: calc(100% - 12px) 50% !important;
        background-repeat: no-repeat !important;
    }
}
